import React from 'react';
import { array, bool, number, func } from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { setCheckoutDrawerOpen } from '../../../actions/checkoutPlan';
import { getPackageById, isRecurringGig } from '../../../reducers/packages';
import { selectPackage } from '../../../actions/packages';
import './style.scss';

const SelectButton = ({ currentPrice, onClick = () => true, hideButton = false }) => {
    const { currencyConverter, shouldDisplayFees } = useGigPageContext();
    const formattedPrice = currencyConverter.convertWithFee(currentPrice);

    return (
        <>
            <div className="price-wrapper">
                <p className="tbody-5">{formattedPrice}</p>
                {shouldDisplayFees && <Text paddingLeft="1.5" color="inherit" as="span">
                    <I18n k="gig_page_perseus.packages.plus_fees" />
                </Text>}
            </div>
            {!hideButton && (
                <Button className="btn-select-package" onClick={onClick}>
                    <I18n k="gig_page_perseus.packages_table.select" />
                </Button>
            )}
        </>
    );
};

SelectButton.propTypes = {
    currentPrice: number,
    onClick: func,
    hideButton: bool,
};

const mapStateToProps = ({ packages }, ownProps) => {
    const packageData = getPackageById(packages, ownProps.id);

    return {
        currentPrice: packageData.currentPrice,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: () => {
        dispatch(selectPackage({ packageId: ownProps.id }));
        dispatch(setCheckoutDrawerOpen(true));
    },
});

const SelectButtonConnect = connect(mapStateToProps, mapDispatchToProps)(SelectButton);

const SelectPackage = ({ packageList, isRecurringGig, hideButtons = false }) => {
    const showTotalTitle = !isRecurringGig;

    return (
        <tr className="select-package">
            <td className="package-row-label">
                {showTotalTitle && <I18n k={'gig_page_perseus.packages_table.total'} />}
            </td>
            {packageList.map(({ id }) => (
                <td key={id}>
                    <SelectButtonConnect id={id} hideButton={hideButtons} />
                </td>
            ))}
        </tr>
    );
};

SelectPackage.propTypes = {
    packageList: array,
    isRecurringGig: bool,
    hideButtons: bool,
};

const mapSelectPackageStateToProps = (state) => {
    const { packages } = state;
    const { packageList } = packages;

    return {
        packageList,
        isRecurringGig: isRecurringGig(packages),
    };
};

export { SelectPackage, SelectButton };
export default connect(mapSelectPackageStateToProps)(SelectPackage);
