import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Container, Stack } from '@fiverr-private/layout_components';
import { GigDescription, GigInfoHeader, GigInfoHeaderProps, GigMetadata } from '@fiverr-private/gig_info';
import { SellerCard } from '@fiverr-private/gig_seller_card';
import { NotableClients } from '@fiverr-private/gig_notable_clients';
import { SellerHighlightsList } from '@fiverr-private/gig_seller_highlights';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ProBenefitsBanner } from '@fiverr-private/gig_page_banners';
import { Ugc } from '../../../types/ugc';
import { Seller } from '../../../types/seller';
import { ABOUT_SELLER, ABOUT_THIS_GIG, RECOMMENDATIONS } from '../../../utils/pageSections';
import { GIG_SUB_HEADER } from '../../../utils/events';
import { prepareSellerCard } from '../../../utils/dataFormatters/prepareSellerCard';
import { prepareSellerHighlights } from '../../../utils/dataFormatters/prepareSellerHighlights';
import { prepareGigDescription } from '../../../utils/dataFormatters/prepareGigDescription';
import { prepareGigMetadata } from '../../../utils/dataFormatters/prepareGigMetadata';
import { prepareNotableClientsProps } from '../../../utils/dataFormatters/prepareNotableClientsProps';
import { metric } from '../../../utils/metric';
import { ProBadge } from '../../shared/ProBadge/ProBadge';
import Workflow from '../../Workflow';
import WorkProcess from '../../WorkProcess';
import Faq from '../../Faq';
import Reviews from '../../Reviews';
import Tags from '../../Tags';
import PackagesTable from '../../PackagesTable';
import OrderDetails from '../../OrderDetails';
import Gallery from '../../Gallery';
import Overview from '../../Overview';
import Portfolio from '../../Portfolio';
import { OutOfOffice } from '../../OutOfOffice/OutOfOffice';
import { Disclaimer } from '../../Disclaimer/Disclaimer';
import { MoreServices } from '../../MoreServices/MoreServices';
import ReviewsSnippet from '../../ReviewsSnippet';
import UGCTranslateButton from '../../UGCTranslateButton';
import PersonalizationWrapper from '../../PersonalizationWrapper';
import { SECTION_NAMES } from '../../PersonalizationWrapper/constants';
import { ExpertList } from '../../ExpertList/ExpertList';
import { LayoutProps } from '../gigThemesConfig';
import { Waypoint } from '../../Waypoint/Waypoint';
import { OffersHourlyRatesBanner } from '../../OffersHourlyRates/OffersHourlyRatesBanner';
import { useOnReviewsClick } from '../../../hooks/useOnReviewsClick';
import { AiPrompt } from '../../AiModels/AiPrompt/AiPrompt';

import {
  expertListContainerStyle,
  proBannerStyle,
  proRebrandingStyle,
  sellerCardStyle,
} from './NonExperientialGigPage.ve.css';

interface NonExperientialGigPageProps extends LayoutProps {
  ugc: Ugc;
  seller: Seller;
}

const _NonExperientialGigPage = (props: NonExperientialGigPageProps) => {
  const {
    overview,
    repeatScore,
    gallery,
    reviews,
    workflow,
    tags,
    info,
    shouldRenderUGCTranslateButton,
    portfolioProps,
    useStickyLayout,
    paymentType,
    shouldRenderComparePersonalizedSection,
    ugc,
    seller,
    sellerCard,
    choiceEligibilities,
    description,
    notableClients,
  } = props;

  const shouldRenderReviews = props.shouldRenderReviews();
  const {
    seller: { isAgency },
    sellerUsername,
    general,
    biEvents,
    sellerLanguageParams,
    pathfinderWrapper,
    isBusinessLayout,
    isProRebrandingLayout,
  } = useGigPageContext();

  const onReviewsClick = useOnReviewsClick();

  const shouldRenderNewProBanner = props.shouldRenderNewProBanner();

  const gigMetadataProps = prepareGigMetadata({ description });

  const sellerCardProps = prepareSellerCard({
    seller,
    sellerCard,
    sellerLanguageParams,
    pathfinderWrapper,
    ugc,
    sellerUsername,
    gigMetadata: gigMetadataProps.gigMetadata,
    general,
    overview,
    onReviewsClick,
    isProRebrandingLayout,
  });

  const { highlights, onHighlightsImpression } = prepareSellerHighlights({
    seller,
    sellerCard,
    repeatScore,
    choiceEligibilities,
    isBusinessLayout,
    isProRebrandingLayout,
    biEvents,
  });
  const shouldRenderHighlights = highlights.length > 0 && !shouldRenderNewProBanner;

  const showGigInfoProBadge = isProRebrandingLayout && general.isPro;
  const { proSubCategories: proSubcategories } = sellerCard;

  const gigInfoTheme = isBusinessLayout ? 'business' : 'default';
  const gigDescriptionProps = prepareGigDescription({ description, ugc });

  const { notableClients: adaptedNotableClients } = prepareNotableClientsProps({ notableClients });

  const gigInfoHeaderProps = {
    biEvents,
    theme: gigInfoTheme,
    badge: showGigInfoProBadge ? <ProBadge isAgency={isAgency} /> : null,
  } satisfies GigInfoHeaderProps;

  const sellerCardClassNames = classNames('seller-card', sellerCardStyle, {
    [proRebrandingStyle]: isProRebrandingLayout,
  });

  return (
    <>
      <OutOfOffice />
      <Overview overview={overview} studioInfo={info} useStickyLayout={useStickyLayout} repeatScore={repeatScore} />

      {shouldRenderUGCTranslateButton && <UGCTranslateButton componentName={GIG_SUB_HEADER} />}

      <Waypoint source={ABOUT_SELLER} />
      <Stack className={sellerCardClassNames}>
        <SellerCard metric={metric} {...sellerCardProps} />
      </Stack>

      {shouldRenderNewProBanner && (
        <ProBenefitsBanner className={classNames('pro-freelancer-banner', proBannerStyle)} />
      )}

      {isProRebrandingLayout && (
        <Container className={classNames('expert-list', expertListContainerStyle)}>
          <ExpertList proSubcategories={proSubcategories} isFoldable columnCount={3} />
        </Container>
      )}

      {shouldRenderHighlights && (
        <Stack className="highlights" gap="4" direction="column">
          <SellerHighlightsList highlights={highlights} isAgency={isAgency} onImpression={onHighlightsImpression} />
        </Stack>
      )}

      {shouldRenderReviews && <ReviewsSnippet reviews={reviews} />}

      <Waypoint source={ABOUT_THIS_GIG} />
      <Stack gap="8" direction="column" className="about-gig">
        <Stack gap="4" direction="column">
          <GigInfoHeader {...gigInfoHeaderProps} />
          <GigDescription {...gigDescriptionProps} biEvents={biEvents} />
        </Stack>
        <Gallery gallery={gallery} isFullView={false} />
        <GigMetadata {...gigMetadataProps} biEvents={biEvents} />
      </Stack>

      <AiPrompt />

      <Disclaimer />

      <div className="notable-clients">
        <NotableClients biEvents={biEvents} isAgency={isAgency} notableClients={adaptedNotableClients} />
      </div>

      <Portfolio {...portfolioProps} />

      <Workflow workflow={workflow} />
      <WorkProcess paymentType={paymentType} />

      <div>
        <PackagesTable />
        <OffersHourlyRatesBanner seller={seller} />
      </div>
      <OrderDetails />

      <Waypoint source={RECOMMENDATIONS} />
      {shouldRenderComparePersonalizedSection() && (
        <PersonalizationWrapper sectionName={SECTION_NAMES.COMPARE} className="personalized-content-compare" />
      )}

      <MoreServices />
      <Faq />

      {shouldRenderReviews && <Reviews reviews={reviews} />}

      <Tags tags={tags} />
    </>
  );
};

const mapStateToProps = ({ ugc }) => ({ ugc });

export const NonExperientialGigPage = connect(mapStateToProps, null)(_NonExperientialGigPage);
