import { translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUB_CATEGORIES } from '../../types/categories';
import { getVoCalculator, isVoiceOverCalculator } from '../calculator';
import { getFormattedLabel, getLabel } from '../translation/pricingFactors/label';
import { Attributes } from '../translation/attributes';
import { selectFirstMilestonePrice, selectTotalMilestonesPrice } from '../../reducers/workProcess';
import { getCurrentPackage } from '../../reducers/packages';
import { RECOMMENDED_TYPES_URL_PARAMS } from '../../reducers/packages/utils/constants';
import { calculateRegularDuration } from '../voiceOver';
import { PackageData } from '../../types/gig/interfaces';
import {
    BASIC_PACKAGE_ID,
    NUMERIC_TYPE,
    PREMIUM_PACKAGE_ID,
    STANDARD_PACKAGE_ID,
    AI_MODEL_PACKAGE_ID,
    UNLIMITED_REVISION,
    UNLIMITED_VALUES,
} from './constants';
import { calcExtrasPrice } from './calcExtrasPrice';

export const calcFirstMilestonePriceWithExtras = (state) => {
    const currentPackage = getCurrentPackage(state);
    const extrasPrice = calcExtrasPrice(currentPackage);
    const firstMilestonePrice = selectFirstMilestonePrice(state);

    return firstMilestonePrice + extrasPrice;
};

export const calcTotalMilestonePriceWithExtras = (state) => {
    const currentPackage = getCurrentPackage(state);
    const extrasPrice = calcExtrasPrice(currentPackage);
    const totalMilestonesPrice = selectTotalMilestonesPrice(state);

    return totalMilestonesPrice + extrasPrice;
};

export const calculateExtraFastDuration = (packageData: PackageData) => {
    const { calculators = [], extraFast } = packageData;

    const voCalculator = calculators.find(isVoiceOverCalculator);

    if (voCalculator?.currentValue) {
        return Math.ceil(voCalculator.currentValue / extraFast.value);
    }

    return extraFast.currentDuration || extraFast.duration;
};

export const calcCurrentDuration = (packageData: PackageData) => {
    const { calculators = [], extraFast, duration, features, customExtras = [] } = packageData;
    let currentDuration = duration;

    if (isExtraFastIncluded(extraFast)) {
        return calculateExtraFastDuration(packageData);
    }

    // TODO make it general for calculators
    const voCalculator = calculators.find(isVoiceOverCalculator);
    if (voCalculator?.currentValue) {
        currentDuration = calculateRegularDuration({ scriptLength: voCalculator.currentValue, packageData });
    }
    features.filter(isPricingFactorIncluded).forEach((feature) => (currentDuration += feature.currentDuration ?? 0));
    customExtras.filter(isPricingFactorIncluded).forEach((customExtra) => (currentDuration += customExtra.duration));

    return currentDuration;
};

export const formatDaysKey = (days) => (days === 1 ? 'gig_page_perseus.general.day' : 'gig_page_perseus.general.days');

export const formatPricingFactorValue = (value) => (isUnlimited(value) ? UNLIMITED_REVISION : value);

export const isUnlimited = (value) => UNLIMITED_VALUES.includes(value);

interface BuildLabelForExtraFastParams {
    currentDuration?: number;
    duration?: number;
}

/**
 * Builds the Extra Fast label based on its duration.
 * The result is the amount of time the Gig Delivery will take when selecting the Extra Fast.
 */
export const buildLabelForExtraFast = ({ currentDuration, duration }: BuildLabelForExtraFastParams): string =>
    translate('gig_page_perseus.packages.extra_fast_single_plural', { params: { count: currentDuration || duration } });

export const getPackageTypeById = (id) => {
    switch (id) {
        case BASIC_PACKAGE_ID:
            return 'gig_page_perseus.packages.basic';
        case STANDARD_PACKAGE_ID:
            return 'gig_page_perseus.packages.standard';
        case PREMIUM_PACKAGE_ID:
            return 'gig_page_perseus.packages.premium';
        case AI_MODEL_PACKAGE_ID:
            return 'gig_page_perseus.packages.ai_model';
        default:
            return '';
    }
};

export const isVoiceOver = ({ subCategoryId }) => SUB_CATEGORIES.VOICE_OVERS === subCategoryId;

export const isPricingFactorIncluded = (pricingFactor) => pricingFactor && pricingFactor.amount > 0;

export const isExtraFastIncluded = (extraFast) => extraFast && extraFast.currentValue > 0;

export const isRecommendedPackage = (packageData) => !!packageData.recommendedType;

export const hasRecommendedPackage = (packageList) =>
    packageList.some((packageData) => isRecommendedPackage(packageData));

export const isValidVoPackage = (calculators, subCategoryId) =>
    !!getVoCalculator(calculators) && isVoiceOver({ subCategoryId });

/**
 * @param {string} type
 * @param {string} name
 * @param {number} value
 * @param {number} subCategoryId The gig subcategory id
 * @return {function} a function that returns an interpolated label (if the feature is number based - it will interpolate appropriately)
 */
export const translateLabelBasedOnCategory =
    ({ type, name, value, subCategoryId }) =>
    () =>
        type === NUMERIC_TYPE
            ? getFormattedLabel({ name, value, subCategoryId })
            : getLabel({ name, subCategoryId }, [Attributes.buyerTitle, Attributes.label]);

/**
 * Filter features which are not included in any package.
 * Aligns all features array of all packages to be on the same size.
 * @param {Array} packageList
 * @return {Array}
 */
export const alignPackagesFeatures = (packageList) => {
    const { mappedFeatures, includedFeatureIds } = mapPackagesFeatures(packageList);

    const alignedPackageList = packageList.map((packageData) => {
        // filter features which are not included in all 3 packages
        const features = packageData.features.filter((feature) => mappedFeatures[feature.id].includedCount > 0);

        // fill features lists which are smaller than the featureIds list
        if (features.length < includedFeatureIds.size) {
            includedFeatureIds.forEach((featureId) => {
                if (features.find((feature) => feature.id === featureId) === undefined) {
                    features.push({ ...mappedFeatures[featureId as number].featureData, included: false });
                }
            });
        }

        return {
            ...packageData,
            features,
        };
    });

    sortPackagesFeatures(alignedPackageList);
    return alignedPackageList;
};

/**
 * Iterates over packages and returns a map of feature id to its included count and in addition a Set of
 * included feature ids.
 * This method also adds as included features which are added via query params.
 * @param {Array} packageList
 * @return {Object}
 */
const mapPackagesFeatures = (packageList) => {
    const mappedFeatures = {};
    const includedFeatureIds = new Set();
    const { queryParameters } = getContext();
    const filteredPackageId = queryParameters.pckg_id ? parseInt(queryParameters.pckg_id as string, 10) : -1;

    packageList.forEach((packageData) => {
        packageData.features.forEach((feature) => {
            if (!mappedFeatures[feature.id]) {
                mappedFeatures[feature.id] = {
                    featureData: feature,
                    includedCount: 0,
                };
            }

            if (feature.included) {
                mappedFeatures[feature.id].includedCount++;
                includedFeatureIds.add(feature.id);
            } else if (filteredPackageId === packageData.id && isFeatureFiltered(feature)) {
                feature.included = true;
                mappedFeatures[feature.id].includedCount++;
                includedFeatureIds.add(feature.id);
            }
        });
    });

    return {
        mappedFeatures,
        includedFeatureIds,
    };
};

/**
 * Checks if a feature is included in query params.
 * @param {Object} feature
 * @return {bool}
 */
export const isFeatureFiltered = (feature) => {
    const { queryParameters } = getContext();
    const { [RECOMMENDED_TYPES_URL_PARAMS.FILTERED_FACTORS]: queryFilteredFactors } = queryParameters;
    const filteredFactors = queryFilteredFactors ? (queryFilteredFactors as string).split(',') : [];

    return filteredFactors.some((filteredFactor) => filteredFactor === feature.name);
};

/**
 * Sorts packages features. Sorting is done according to the included attribute.
 * @param {Object} packageList
 * @return {void}
 */
export const sortPackagesFeatures = (packageList) => {
    const featuresOrder = {};
    let orderIndex = 1;

    packageList
        .slice()
        .reverse()
        .forEach((packageData) => {
            packageData.features.forEach((feature) => {
                if (feature.included && !featuresOrder[feature.id]) {
                    featuresOrder[feature.id] = orderIndex++;
                }
            });
        });

    packageList.forEach((packageData) => {
        packageData.features.sort((feature1, feature2) => {
            const featureOrder1 = featuresOrder[feature1.id],
                featureOrder2 = featuresOrder[feature2.id];
            if (featureOrder1 < featureOrder2) {
                return -1;
            } else {
                return 1;
            }
        });
    });
};
