import React from 'react';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bool, object, func, string, number } from 'prop-types';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ActionCtas } from '@fiverr-private/gig_page_nav';
import { Container, Stack } from '@fiverr-private/layout_components';
import { CustomerProtectionBanner } from '@fiverr-private/gig_page_banners';
import { LoyaltyIndicationBanner } from '@fiverr-private/loyalty_indication';
import StudioBox from '../../StudioBox';
import PackagesTabs from '../../PackagesTabs';
import ContactSeller from '../../ContactSeller';
import { shouldRenderContactSeller } from '../../../utils/contactSeller';
import CollectWrapper from '../../TopNav/CollectWrapper';
import ReportBtn from '../../TopNav/ReportGig/ReportBtn';
import PayLaterMessage from '../../PayLaterMessage/PayLaterMessage';
import { getPackageById, getSelectedPackageId } from '../../../reducers/packages';
import { useExperiments } from '../../../hooks/useExperiments';
import { SidebarHighlights } from './SidebarHighlights';

import { contactWrapperClass } from './SidebarContent.ve.css';

const SidebarContent = ({ customOrder, useStickyLayout, className = '', setRef = noop, seller = {}, currentPrice }) => {
    const { general, loyaltyIndication, currencyConverter, isBusinessLayout } = useGigPageContext();
    const { isStudio } = general;
    const shouldShowLoyaltyIndication = Boolean(loyaltyIndication);
    const currency = currencyConverter.getCurrency();
    const { inHourlyIndicationExperiment } = useExperiments();

    const shouldShowCustomerProtectionBanner = useStickyLayout && isBusinessLayout && seller?.isPro;

    return (
        <aside className={classNames(className, 'sidebar-content')} ref={setRef}>
            <Container paddingBottom="3" display={{ default: 'none', md: 'block' }}>
                <ActionCtas ReportGigButtonWrapper={ReportBtn} CollectionCta={CollectWrapper} />
            </Container>

            {isStudio && <StudioBox />}

            <PackagesTabs />

            <Stack order={55} className={contactWrapperClass} borderRadius="xl" direction="column" wrap="nowrap">
                {shouldRenderContactSeller({ general, useStickyLayout }) && (
                    <ContactSeller customOrder={customOrder} style={{ backgroundColor: 'inherit', borderRadius: 'inherit', paddingBottom: 0 }} />
                )}
                <SidebarHighlights shouldUseStickyLayout={useStickyLayout} seller={seller}/>
            </Stack>

            {!inHourlyIndicationExperiment && <>
            {shouldShowLoyaltyIndication && (
                <LoyaltyIndicationBanner type="gig_page"
                    containerStyles={{ display: { default: 'none', md: 'flex' }, marginBottom: '0'}}
                    loyaltyIndicationData={loyaltyIndication}
                    currency={currency}
                    isCollapsible={inHourlyIndicationExperiment}
                    gigInfo={{ gigPriceInUSDCents: currentPrice || 0, isProSeller: seller?.isPro, sellerName: seller?.user?.profile?.displayName }} />
            )}

            <PayLaterMessage />

            <Container hidden={{
                default: true,
                md: !shouldShowCustomerProtectionBanner,
            }}
            >
                <CustomerProtectionBanner/>
            </Container>
            </>
            }

            { inHourlyIndicationExperiment && <>
                <Banners shouldShowCustomerProtectionBanner={shouldShowCustomerProtectionBanner} currentPrice={currentPrice} />
                <PayLaterMessage/>
            </>}

        </aside>
    );
};

SidebarContent.propTypes = {
    customOrder: object,
    setRef: func,
    useStickyLayout: bool,
    seller: object,
    className: string,
    currentPrice: number
};


export const Banners = ({ currentPrice, shouldShowCustomerProtectionBanner }) => {
    const { loyaltyIndication, currencyConverter, seller } = useGigPageContext();
    const shouldShowLoyaltyIndication = Boolean(loyaltyIndication);
    const currency = currencyConverter.getCurrency();
    const { inHourlyIndicationExperiment } = useExperiments();

    if (!inHourlyIndicationExperiment) return null;

    return (
        <Stack
            hidden={{
                default: true,
                md: false,
            }}
            direction="column"
            gap="0"
        >
            {shouldShowLoyaltyIndication && (
                <LoyaltyIndicationBanner
                    type="gig_page"
                    loyaltyIndicationData={loyaltyIndication}
                    currency={currency}
                    isCollapsible
                    containerStyles={shouldShowCustomerProtectionBanner ? { borderBottomLeftRadius: 'none', borderBottomRightRadius: 'none' } : undefined}
                    gigInfo={{
                        gigPriceInUSDCents: currentPrice || 0,
                        isProSeller: seller?.isPro,
                        sellerName: seller?.displayName,
                    }}
                />
            )}
            {shouldShowCustomerProtectionBanner && <CustomerProtectionBanner
                containerStyles={shouldShowLoyaltyIndication ? { borderTopLeftRadius: 'none', borderTopRightRadius: 'none', borderTop: 'none', style: { borderTop: 'none' } } : undefined}
                isCollapsible
            />}
        </Stack>
    );
};

Banners.propTypes = {
    currentPrice: number,
    shouldShowCustomerProtectionBanner: bool,
}

const mapStateToProps = (state) => {
    const { packages } = state;
    const packageId = getSelectedPackageId(state);
    const { currentPrice } = getPackageById(packages, packageId) || {};

    return {
        currentPrice
    };
};


export default connect(mapStateToProps)(SidebarContent);
