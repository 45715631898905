import { useEffect } from 'react';

interface useHashChangeProps {
    callback: (hash: string) => void;
    removeHashAfterCallback?: boolean;
}

export const useHashChange = ({ callback, removeHashAfterCallback = true }: useHashChangeProps) => {
    useEffect(() => {
        const handleHashChange = () => {
            const currentHash = window.location.hash;
            callback(currentHash);
        };
        window.addEventListener('hashchange', handleHashChange);
        handleHashChange();

        if (removeHashAfterCallback) {
            window.location.hash = '';
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [callback, removeHashAfterCallback]);
};
