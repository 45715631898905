import React, { useEffect, useRef, useState } from 'react';
import { bool } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Button, Popover } from '@fiverr-private/fit';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ChevronDownIcon, ChevronUpIcon } from '@fiverr-private/icons';
import { Container } from '@fiverr-private/layout_components';
import { reportContactSellerOptionsClick } from '../../../utils/contactSeller';
import { fibMigrationFlow, FibTriggers } from '../../../utils/fibMigrationFlow';
import { trackContactMeClickEvent } from '../../../utils/gtmTracking/gtmTracking';
import PopoverContent from './PopoverContent';

import './style.scss';

const ContactSellerOptions = ({ customOrderEligible }) => {
    const { biEvents, seller } = useGigPageContext();
    const contactSellerButtonRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const { isAgency } = seller;
    const ctaTitle = isAgency ?
        `gig_page_perseus.contact_seller.title_agency` :
        `gig_page_perseus.contact_seller.title`;

    useEffect(() => {
        handlePersistedFibMigrationTriggers(contactSellerButtonRef.current);
    }, [contactSellerButtonRef]);

    const onOpen = async() => {
        setIsOpen(true);
        reportContactSellerOptionsClick(biEvents);
        trackContactMeClickEvent();
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const handlePersistedFibMigrationTriggers = (buttonEl) => {
        if (!buttonEl) {
            return;
        }

        const isAskQuestionTriggerPersisted = fibMigrationFlow.isTriggerIdPersisted(FibTriggers.Contact);
        const isGetQuoteTriggerPersisted = fibMigrationFlow.isTriggerIdPersisted(FibTriggers.GetQuote);

        if (isAskQuestionTriggerPersisted || isGetQuoteTriggerPersisted) {
            // README: We have to open dropdown for run logic inside options
            buttonEl.dispatchEvent(new Event('click', { bubbles: true }));
        }
    };

    return (
        <div className="contact-seller-options-wrapper">
            <Popover
                className="contact-seller-popover"
                contentClassName="contact-seller-popover-content"
                boxContentClassName="popover-box-content"
                appendToBody={false}
                onOpen={onOpen}
                onClose={onClose}
                content={<PopoverContent customOrderEligible={customOrderEligible} />}
            >
                <Button
                    className="contact-seller-options-btn"
                    ref={contactSellerButtonRef}
                    color={Button.COLORS.GREY}
                    fill={Button.FILLS.GHOST}
                >
                    <I18n k={ctaTitle}/>
                    <Container marginLeft="2" className="button-icon">
                        {isOpen ? (
                            <ChevronUpIcon size="10" color="grey_1100"/>
                        ) : (
                            <ChevronDownIcon size="10" color="grey_1100"/>
                        )}
                    </Container>
                </Button>
            </Popover>
        </div>
    );
};

ContactSellerOptions.propTypes = {
    customOrderEligible: bool,
};

export default ContactSellerOptions;
