import React from 'react';
import { Button } from '@fiverr-private/go_shared_ui';
import { ArrowRightIcon } from '@fiverr-private/visuals';
import { Stack } from '@fiverr-private/layout_components';
import pathfinder from '@fiverr-private/pathfinder';
import { Typography } from '@fiverr-private/typography';

interface AiPackageContentProps {
  aiModelId: string;
  aiModelName: string;
  background: string;
  sellerName: string;
}
export const AiPackageContent: React.FC<AiPackageContentProps> = ({
  aiModelId,
  aiModelName,
  background,
  sellerName,
}) => {
  const buttonHref = pathfinder('ai_hub_playground_create_tab_new_model_conversation', {
    seller_username: sellerName,
    model_slug: aiModelId,
  });

  return (
    <Stack
      className="reveal-diagonal"
      direction="column"
      padding="8"
      gap="6"
      borderBottomLeftRadius="xl"
      borderBottomRightRadius="xl"
    >
      <Stack direction="column" gap="1.5">
        <Typography color="white" fontWeight="semibold" size="b_md">
          {aiModelName}
        </Typography>
        <Typography color="white" fontWeight="normal" size="b_sm">
          The new way of immediate creation
        </Typography>
      </Stack>
      <Stack height="260px" borderRadius="lg" justifyContent="center" alignItems="center" background={background}>
        <Button href={buttonHref}>
          Create with my style
          <ArrowRightIcon />
        </Button>
      </Stack>
    </Stack>
  );
};
