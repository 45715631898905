import React, { useState, useCallback } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { func, string } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { FLOW_CONTEXT_TYPES, shouldDisplayMigrationPopup } from '@fiverr-private/pro_migration';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { useSuccessSignCtaEvent, BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { BriefDrawer } from '@fiverr-private/ai_brief';
import { isLoggedInUser } from '../../../utils/isLoggedInUser';
import { triggerFibMigrationPopup } from '../../../utils/fibMigrationPopup';
import { useContactAvailability } from '../../../hooks';
import { onGuestClick, redirectToInboxConversation } from '../../../utils/contactSeller';
import { trackContactMeClickEvent } from '../../../utils/gtmTracking/gtmTracking';
import { useOpenUserActivationModalWrapper } from '../UserActivationModalWrapper/utils';
import { SUPPORTED_ACTIONS } from '../UserActivationModalWrapper/constants';
import UserActivationModalWrapper from '../UserActivationModalWrapper';
import { updateShowFirstMessagePopup } from '../../../actions/widgets';
import { useExperiments } from '../../../hooks/useExperiments';
import { useAIBrief } from '../../../hooks/useAIBrief';
import { useRecentInteractions } from '../../../hooks/useRecentInteractions';

const ContactTrigger = ({
    onClick,
    source,
    children,
    updateShowFirstMessagePopup,
    triggerId,
    triggerCopy,
    triggerPlacement,
}) => {
    const { pathfinderWrapper, biEvents, currentUser, seller } = useGigPageContext();
    const { shouldRenderAndOpenUserActivationModal } = useOpenUserActivationModalWrapper();
    const [renderAndOpenUserActivationModal, setRenderAndOpenUserActivationModal] = useState(false);
    const [isAIBriefActivationModalMounted, setIsAIBriefActivationModalMounted] = useState(false);
    const { isContactAvailable } = useContactAvailability();
    const { inAIBriefTest } = useExperiments();
    const { checkRecentInteractions } = useRecentInteractions();

    const triggerFirstMessagePopup = useCallback(
        (showModal = true) => {
            const { isTouch } = getContext();

            if (isTouch) {
                redirectToInboxConversation(pathfinderWrapper);

                return false;
            } else if (showModal) {
                updateShowFirstMessagePopup(true);
            }

            return true;
        },
        [pathfinderWrapper, updateShowFirstMessagePopup]
    );

    const { openDrawerAiBriefDrawer, props, ActivationModalComponent } = useAIBrief({
        onContactSellerClick: triggerFirstMessagePopup,
    });

    const successCtaEventSource = `gig-page-${source}`;
    const handleClick = async () => {
        const triggerOptions = onClick();
        const showModal = get(triggerOptions, 'showModal', true);
        const { experience } = getContext();
        trackContactMeClickEvent();
        
        if (!isLoggedInUser()) {
            onGuestClick({
                biEvents,
                pathfinderWrapper,
                modalOptions: experience.isBusiness
                    ? {
                          source,
                          triggerCta: {
                              type: BUSINESS_TRIGGER_CTA.CONTACT,
                              source: successCtaEventSource,
                              data: { sellerName: seller.displayName },
                          },
                      }
                    : {
                          triggerId,
                          triggerCopy,
                          triggerPlacement,
                      },
            });

            return false;
        }

        if (await shouldRenderAndOpenUserActivationModal(currentUser)) {
            if (inAIBriefTest) {
                setIsAIBriefActivationModalMounted(true);
            } else {
                setRenderAndOpenUserActivationModal(true);
            }

            return false;
        }

        if (inAIBriefTest) {
            if (await checkRecentInteractions()) {
                redirectToInboxConversation(pathfinderWrapper);
                return false;
            }
            openDrawerAiBriefDrawer();
            return true;
        }

        if (shouldDisplayMigrationPopup()) {
            triggerFibMigrationPopup({
                trigger: FLOW_CONTEXT_TYPES.contact,
                triggerSource: successCtaEventSource,
                sellerDisplayName: seller.displayName,
            });

            return false;
        }

        return triggerFirstMessagePopup(showModal);
    };

    // should have source uniq
    useSuccessSignCtaEvent({
        type: BUSINESS_TRIGGER_CTA.CONTACT,
        source: successCtaEventSource,
        callback: handleClick,
    });

    const triggerProps = {
        onClick: handleClick,
    };

    if (!isContactAvailable) {
        return null;
    }

    return (
        <>
            {children(triggerProps)}
            <BriefDrawer {...props} />
            {isAIBriefActivationModalMounted && <ActivationModalComponent />}
            <UserActivationModalWrapper
                renderAndOpen={renderAndOpenUserActivationModal}
                onClose={() => setRenderAndOpenUserActivationModal(false)}
                action={SUPPORTED_ACTIONS.CONTACT_SELLER}
            />
        </>
    );
};

ContactTrigger.propTypes = {
    onClick: func.isRequired,
    children: func,
    source: string,
    updateShowFirstMessagePopup: func,
    triggerId: string,
    triggerCopy: string,
    triggerPlacement: string,
};

const mapDispatchToProps = { updateShowFirstMessagePopup };

export { ContactTrigger };
export default connect(null, mapDispatchToProps)(ContactTrigger);
