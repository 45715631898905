import React, { useRef } from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { GuaranteeIcon } from '@fiverr-private/visuals';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text, Link } from '@fiverr-private/typography';
import { useEnteredView } from '@fiverr-private/hooks';
import {
  Collapsible,
  CollapsibleHeader,
  CollapsibleContent,
  CollapsibleHeaderChevron,
} from '@fiverr-private/disclosure';
import { getContext } from '@fiverr-private/fiverr_context';
import { useVideoLoop } from '../../hooks/useVideoLoop';
import { bqClickedOnCustomerProtection, bqCustomerProtectionBannerIsShown } from './biEvents';

const HREF =
  'https://help-pro.fiverr.com/hc/en-us/articles/21333583493649-Fiverr-Pro-satisfaction-guarantee-for-clients';
const VIDEO_SRC =
  'https://fiverr-res.cloudinary.com/video/upload/v1/video-attachments/generic_asset/asset/19e22a9e911015e86da0e8612a67b3f9-1712849343286/guarantee_icon_for_white';

const VIDEO_LOOPS = 2;

export interface CustomerProtectionBanner {
  isCollapsible?: boolean;
  defaultExpanded?: boolean;
  containerStyles?: React.ComponentProps<typeof Stack> & React.RefAttributes<HTMLElement>;
}

export const CustomerProtectionBanner = ({
  isCollapsible = false,
  defaultExpanded = false,
  containerStyles,
}: CustomerProtectionBanner) => {
  const { biEvents, gigTheme, isBusinessLayout, seller } = useGigPageContext();
  const showBanner = isBusinessLayout && seller?.isPro;

  const observableRef = useRef<HTMLElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { playVideo, onVideoEnded, onVideoCanPlayThrough, videoReady } = useVideoLoop({
    numOfLoops: VIDEO_LOOPS,
    videoType: 'video/webm;codecs=vp9',
    videoRef,
  });

  const enrichmentData = biEvents.getBigQueryEnrichmentData();
  const { organizationId } = getContext();

  const onLinkClick = () => {
    biEvents.sendRawBigQueryEvent(
      bqClickedOnCustomerProtection({
        ...enrichmentData,
        organizationId,
        gigTheme,
      })
    );
  };

  useEnteredView(observableRef, {
    threshold: 0.9,
    onEntered: () => {
      playVideo();
      biEvents.sendRawBigQueryEvent(
        bqCustomerProtectionBannerIsShown({
          ...enrichmentData,
          organizationId,
          gigTheme,
        })
      );
    },
  });

  return (
    <Stack
      ref={observableRef}
      gap="3"
      paddingX="4"
      hidden={{
        default: true,
        md: !showBanner,
      }}
      paddingY={isCollapsible ? '2.5' : '4'}
      borderWidth="sm"
      alignItems="flexStart"
      borderColor="border"
      borderRadius="md"
      data-testid="customer_protection_banner"
      {...containerStyles}
    >
      <Container paddingY="1" hidden={videoReady}>
        <GuaranteeIcon size="lg" color="grey_1200" />
      </Container>
      <Container hidden={!videoReady}>
        <video
          ref={videoRef}
          onEnded={onVideoEnded}
          onCanPlayThrough={onVideoCanPlayThrough}
          width={24}
          height={29}
          controls={false}
          muted
          playsInline
        >
          <source src={VIDEO_SRC} type="video/webm" />
        </video>
      </Container>
      <Stack direction="column" gap="0.5" width="100%">
        <Collapsible disabled={!isCollapsible} defaultExpanded={!isCollapsible || defaultExpanded}>
          <CollapsibleHeader paddingTop="1.5" paddingBottom={isCollapsible ? '0' : '0.5'} width="100%">
            <Text size="b_sm" fontWeight="bold">
              {translate('gig_page_banners.customer_protection_banner.title')}
            </Text>

            {isCollapsible && <CollapsibleHeaderChevron />}
          </CollapsibleHeader>
          <CollapsibleContent>
            <Text size="b_sm">
              {translate('gig_page_banners.customer_protection_banner.content')}
              &nbsp;
              <Link href={HREF} target="_blank" onClick={onLinkClick}>
                {translate('gig_page_banners.customer_protection_banner.link')}
              </Link>
            </Text>
          </CollapsibleContent>
        </Collapsible>
      </Stack>
    </Stack>
  );
};
