import React from 'react';
import { isEmpty } from 'lodash';
import { AiDeliveryBadge } from '@fiverr-private/go_shared_ui';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { AiModelsTooltip } from '../AiModelsTooltip/AiModelsTooltip';

export const AiDeliveryBadgeWrapper = () => {
  const {
    aiModels = [],
    seller: { username: sellerName },
  } = useGigPageContext();

  if (isEmpty(aiModels) || !sellerName) return null;

  return (
    <AiDeliveryBadge
      tooltipContent={<AiModelsTooltip sellerName={sellerName} />}
      popoverContentProps={{ disablePadding: true, backgroundColor: 'black', withDelay: true }}
      shimmerAnimation
    />
  );
};
