import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bool, func, number, object, oneOf } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { LazyComponent } from '@fiverr-private/orca';
import { useSuccessSignCtaEvent, BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { setCheckoutDrawerOpen } from '../../../../actions/checkoutPlan';
import { isCheckoutDrawerOpened } from '../../../../reducers/checkoutPlan';
import { getCurrentPackage, getGigQuantity, getPaymentOption, isRecurringGig } from '../../../../reducers/packages';
import { CHECKOUT_PLANS } from '../../../../utils/checkoutPlan/constants';
import { reportDrawerClosedEvent } from '../../../PaymentDrawer/utils/events';
import {
    countAvailablePricingFactors,
    countSelectedPricingFactors,
} from '../../../PaymentDrawer/ExtrasList/utils/ExtrasBuilder';
import { isLoggedInUser } from '../../../../utils/isLoggedInUser';
import { buildUrlParamMapFromState } from '../../../../utils/queryParams/serialize/buildUrlParamMapFromState';
import { SELLER_COUPON_TYPES } from '../../../GigPage/constants';
import SubscriptionDrawerLabel from '../../../shared/PackageContent/HeaderRecurring/SubscriptionDrawerLabel';
import { triggerSignUp } from '../../../../utils/signUp';
import { useHashChange } from '../../../../hooks/useHashChange/useHashChange';
import { PACKAGE_HASH_PREFIX } from '../../../../hooks/useHashChange/constants';
import OpenDrawerButton from './OpenDrawerButton';
import { FOOTER_CONFIG } from './config';
import { removeDrawerTrigger, shouldTriggerDrawerOpen, triggerSignIn } from './utils';
import { logAndToastLazyImportError, reportCtaType, sendClickedOnContinueToCheckoutEvent } from './utils/events';

import './index.scss';

const PaymentDrawerFooter = ({
    isCheckoutDrawerOpened,
    isRecurringGig,
    paymentOption,
    setCheckoutDrawerOpen,
    pricingFactorsCount,
    selectedPricingFactors,
    gigQuantity,
    urlParamMap,
    packageId,
}) => {
    const { biEvents, coupon, logger, general, pathfinderWrapper } = useGigPageContext();

    const [imported, setImported] = useState(false);
    const [openedDrawer, setDrawerOpened] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const { gigId } = general;
    const { FooterInfo } = FOOTER_CONFIG[paymentOption];

    const lazyImport = async () => import(/* webpackChunkName: 'PaymentDrawer' */ '../../../PaymentDrawer');

    const onLazyImportError = (error) => {
        // If need to retry again, toggle the shouldImport to force reload
        setIsLoading(false);
        setImported(false);
        setCheckoutDrawerOpen(false);

        logAndToastLazyImportError({ logger, error, paymentOption });
    };

    const openDrawer = () => {
        // sendCheckoutDrawerViewedEvent(general, biEvents, currentUser?.username); // for DQA

        setDrawerOpened(true);
    };

    const closeDrawer = () => {
        setDrawerOpened(false);
        setCheckoutDrawerOpen(false);

        reportDrawerClosedEvent({ paymentOption, pricingFactorsCount, selectedPricingFactors, gigQuantity, biEvents });
    };

    /* Triggered just before attempting to LazyImport, happens once. */
    const beforeLoad = () => {
        setIsLoading(true);
    };

    /* Triggered after finishing to LazyImport */
    const afterLoad = () => {
        setIsLoading(false);
        openDrawer();
    };

    const onClick = () => {
        setCheckoutDrawerOpen(true);
    };

    /** Handle flow when -
     * -- user is logged in after page is refreshed with a temporarily persisted Drawer Trigger
     * -- arriving from `order page` with an Order Again button - a Gig Page URL is generated with a `openDrawer` param
     * @see extractQueryParams
     * @ref [BuyItAgain](https://github.com/fiverr/orders_sphinx/blob/afda76e0f9d9b244b156d6a460a5a9820ae510ff/apps/order_page_perseus/src/apps/buyer/components/BuyItAgain/index.js#L49)
     * */
    const handlePageLoad = () => {
        if (shouldTriggerDrawerOpen(gigId)) {
            // updates store to trigger re-render and moving to the `handleDrawerIsOpened` flow
            setCheckoutDrawerOpen(true);
        } else {
            reportCtaType(paymentOption);
        }

        removeDrawerTrigger();
    };

    const handleDrawerIsOpened = async () => {
        if (isLoggedInUser()) {
            const shouldLazyLoadDrawer = !imported;
            if (shouldLazyLoadDrawer) {
                setImported(true);
            } else {
                openDrawer();
            }
        } else {
            const { experience } = getContext();
            const modalOptions = experience?.isBusiness
                ? {
                    source: 'gig_page_payment',
                    triggerCta: {
                        type: BUSINESS_TRIGGER_CTA.PURCHASE,
                        source: gigId,
                    },
                }
                : { source: 'gig_page_payment' }

            if (experience?.isBusiness) {
                triggerSignUp({
                    biEvents,
                    pathfinderWrapper,
                    options: {
                        ...modalOptions,
                    },
                });
            } else {
                triggerSignIn(gigId, urlParamMap, modalOptions);
            }

            setCheckoutDrawerOpen(false); /** in case the user aborts the modal - enable the CTA click event */
        }
    };

    useSuccessSignCtaEvent({
        type: BUSINESS_TRIGGER_CTA.PURCHASE,
        source: gigId,
        callback: handleDrawerIsOpened,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(handlePageLoad, []);

    /** Handles Open Drawer Flow when user clicks to open Drawer*/
    useEffect(() => {
        if (isCheckoutDrawerOpened) {
            sendClickedOnContinueToCheckoutEvent(biEvents);
            handleDrawerIsOpened().catch(logger.warn);
        } else {
            setDrawerOpened(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckoutDrawerOpened]);

    useHashChange({
        callback: (hash = '') => {
            const fullHash = hash.slice(1);
            const hashPackageId = fullHash.replace(PACKAGE_HASH_PREFIX, '');
            const numericHashPackageId = Number(hashPackageId);
            const isPackageHash = fullHash.includes(PACKAGE_HASH_PREFIX);
            const isValidPackageId = isPackageHash && packageId === numericHashPackageId;

            if (isValidPackageId) {
                setCheckoutDrawerOpen(true);
            }
        }
    });

    {
        /* When the promotion is present, the SubscriptionDrawerLabel component
        is moved from the top of the package content under the "Continue" CTA. */
    }
    const shouldShowSubscriptionLabel =
        isRecurringGig &&
        coupon &&
        coupon.type === SELLER_COUPON_TYPES.PROMOTION;

    return (
        <footer className="tab-footer drawer-payment-footer">
            <OpenDrawerButton onClick={onClick} loading={loading} />
            {shouldShowSubscriptionLabel ? (
                <div className="m-t-24 m-b-4">
                    <SubscriptionDrawerLabel />
                </div>
            ) : (
                <FooterInfo />
            )}
            <LazyComponent
                lazyImport={lazyImport}
                shouldImport={imported}
                onError={onLazyImportError}
                beforeLoad={beforeLoad}
                afterLoad={afterLoad}
                componentProps={{ opened: openedDrawer, paymentOption, closeDrawer }}
                fallback={null}
            />
        </footer>
    );
};

PaymentDrawerFooter.propTypes = {
    isCheckoutDrawerOpened: bool,
    isRecurringGig: bool,
    paymentOption: oneOf(Object.values(CHECKOUT_PLANS)),
    setCheckoutDrawerOpen: func,
    pricingFactorsCount: number,
    selectedPricingFactors: number,
    gigQuantity: number,
    urlParamMap: object,
    packageId: number,
};

const mapStateToProps = (state) => {
    const paymentOption = getPaymentOption(state);
    const selectedPackageData = getCurrentPackage(state);
    const gigQuantity = getGigQuantity(state);
    const urlParamMap = buildUrlParamMapFromState(state);

    return {
        paymentOption,
        gigQuantity,
        urlParamMap,
        isCheckoutDrawerOpened: isCheckoutDrawerOpened(state),
        isRecurringGig: isRecurringGig(state.packages),
        pricingFactorsCount: countAvailablePricingFactors(selectedPackageData),
        selectedPricingFactors: countSelectedPricingFactors(selectedPackageData),
    };
};

const mapDispatchToProps = { setCheckoutDrawerOpen };

export { PaymentDrawerFooter };
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDrawerFooter);
