import React from 'react';
import { bool, node, string } from 'prop-types';
import { Container } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { reportContactSellerClick } from '../../../utils/contactSeller';
import ContactTrigger from '../../shared/ContactTrigger';

const ContactSellerBtn = ({ asContactSellerOption, mixpanelSourceComponent, bqSourceName, triggerId, triggerCopy, triggerPlacement, children }) => {
    const { biEvents } = useGigPageContext();
    const onClick = () =>
        reportContactSellerClick({
            biEvents,
            mixpanelSourceComponent,
            bqSourceName,
            metricKey: 'contact_seller.click',
        });

    return (
        <Container textAlign="center">
            <ContactTrigger
                className="btn-contact-seller"
                source="contact-btn"
                onClick={onClick}
                bqSourceName={bqSourceName}
                triggerId={triggerId}
                triggerCopy={triggerCopy}
                triggerPlacement={triggerPlacement}
            >
                {(triggerProps) =>
                    asContactSellerOption
                        ? <Container {...triggerProps}>{children}</Container>
                        : <Button {...triggerProps} variant="outline" fullWidth>{children}</Button>
                }
            </ContactTrigger>
        </Container>
    );
};

ContactSellerBtn.propTypes = {
    asContactSellerOption: bool,
    mixpanelSourceComponent: string,
    bqSourceName: string,
    children: node,
    triggerId: string,
    triggerCopy: string,
    triggerPlacement: string
};

export default ContactSellerBtn;
