import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { extractUGC } from '../utils/ugc/extractUGC';
import { extractSellerTranslationUGC } from '../utils/ugc/extractSellerTranslationUGC/extractSellerTranslationUGC';
import { extractMachineTranslationUGC, shouldShowOriginal } from '../utils/ugc/extractMachineTranslationUGC';
import reducer from '../reducers';

const createUGCMap = (props) => ({
    original: extractUGC(props),
    translated: extractMachineTranslationUGC(props),
    translatedBySeller: extractSellerTranslationUGC(props),
});

const createGigStore = (props) => {
    const ugcMap = createUGCMap(props);
    const showOriginal = shouldShowOriginal(props);
    const { packages, studio, general, workProcess, tags, rollouts, aiModels } = props;
    const reducers = reducer({ packages, studio, general, ugcMap, showOriginal, workProcess, tags, rollouts, aiModels });

    return createStore(reducers, applyMiddleware(ReduxThunk));
};

export default createGigStore;
