import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import SafePureComponent from '../shared/SafePureComponent';
import { isSinglePackage } from '../../reducers/packages';
import SinglePackage from './SinglePackage';
import TriplePackage from './TriplePackage';

import './style.scss';

const PackagesTabs = ({ shouldRender = true, isSinglePackage }) => {
    if (!shouldRender) {
        return null;
    }

    return isSinglePackage ? <SinglePackage /> : <TriplePackage />;
};

PackagesTabs.propTypes = {
    shouldRender: bool,
    isSinglePackage: bool,
};

const mapStateToProps = ({ packages }) => ({
    shouldRender: packages.packageList.length > 0,
    isSinglePackage: isSinglePackage(packages),
});

export { PackagesTabs };
export default SafePureComponent(connect(mapStateToProps)(PackagesTabs));
