import { isActive } from '@fiverr-private/rollout_js';
import { EXPERIMENT_TYPE, RequestContext } from '@fiverr-private/perseus';
import { ABTests } from '@fiverr-private/fiverr_context';
import { ExperimentAllocationRequest } from '@fiverr-private/perseus/src/modules/experiment';
import { HAWKS_PRICE_UPFRONT } from '../../../../../utils/experiments/experimentsEnum';
import { Rollouts, ROLLOUTS } from '../../../../../types/rollouts';
import { inExperiment } from '../../../../../utils/experiments';

export const CALIFORNIA_REGION_CODE = 'CA';

const isEligibleForAllocation = async () => {
    const { userId, userGuid } = RequestContext;
    return isActive(ROLLOUTS.HAWKS_PRICE_UPFRONT, userId || userGuid);
};

export const priceUpfrontExperiment: ExperimentAllocationRequest = {
    id: HAWKS_PRICE_UPFRONT,
    experimentType: EXPERIMENT_TYPE?.GUEST,
    shouldAllocate: isEligibleForAllocation,
    numOfGroups: 2,
};

export const getShouldDisplayFees = (abTests: ABTests, rollouts: Rollouts, regionCode?: string): boolean =>
    rollouts[ROLLOUTS.HAWKS_PRICE_UPFRONT] &&
    inExperiment(HAWKS_PRICE_UPFRONT, abTests) &&
    regionCode === CALIFORNIA_REGION_CODE;
