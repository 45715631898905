import React from 'react';
import { isEmpty } from 'lodash';
import { Container, Stack } from '@fiverr-private/layout_components';
import { NewBadge } from '@fiverr-private/badges';
import { Typography } from '@fiverr-private/typography';
import { SparkSolidIcon } from '@fiverr-private/visuals';
import { Input, FormElement } from '@fiverr-private/forms';
import pathfinder from '@fiverr-private/pathfinder';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Button } from '@fiverr-private/go_shared_ui';

import * as style from './AiPromt.ve.css';

export const AiPrompt: React.FC = () => {
  const {
    aiModels = [],
    seller: { username },
  } = useGigPageContext();

  if (isEmpty(aiModels) || !username) return null;

  const url = pathfinder('ai_hub_playground_create_tab_new_model_conversation', {
    seller_username: username,
  });

  return (
    <Stack className={style.promptContainer} direction="column" paddingX="10" paddingY="6" gap="4">
      <Stack gap="2">
        <Typography size="b_sm" color="white">
          Check out our new AI feature
        </Typography>
        <NewBadge />
      </Stack>
      <Typography as="h2" className={style.promptHeader}>
        What would you like to create?
      </Typography>
      <Container position="relative">
        <form action={url}>
          <FormElement>
            <Input className={style.promptInput} name="prompt" placeholder="10 Dog illustrations for a book..." />
          </FormElement>

          <Button className={style.promptButton} type="submit">
            <SparkSolidIcon />
            placeholder
          </Button>
        </form>
      </Container>
    </Stack>
  );
};
