import React from 'react';
import pathfinder from '@fiverr-private/pathfinder';
import { Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { GoCloudIcon, Button } from '@fiverr-private/go_shared_ui';
import { SparkSolidIcon } from '@fiverr-private/visuals';

import * as style from './AiModelsTooltip.ve.css';

interface AiModelsTooltipProps {
  sellerName: string;
}
export const AiModelsTooltip: React.FC<AiModelsTooltipProps> = ({ sellerName }) => {
  const url = pathfinder('ai_hub_playground_create_tab_new_model_conversation', {
    seller_username: sellerName,
  });

  return (
    <Stack className={style.container} padding="3" direction="column">
      <Stack
        width="304px"
        height="142px"
        justifyContent="center"
        alignItems="center"
        background="linear-gradient(360deg, #F2C4BD 0%, #EBB1A4 46.83%, #EFA5B6 100%)"
      >
        <Button className={style.button} href={url} target="_blank">
          <SparkSolidIcon />
          placeholder
        </Button>
      </Stack>
      <Stack gap="1" direction="row" justifyContent="flexStart" alignItems="center">
        <GoCloudIcon size={14} />
        <Typography size="b_sm" fontWeight="semibold" lineHeight="b_sm" color="white">
          {sellerName}’s placeholder
        </Typography>
      </Stack>
    </Stack>
  );
};
