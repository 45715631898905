import React from 'react';
import { connect } from 'react-redux';
import { array, bool, func, number, object } from 'prop-types';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { useIsClient } from '@fiverr-private/hooks';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import PackageContent from '../shared/PackageContent';
import SafePureComponent from '../shared/SafePureComponent';
import { getPackageById, getSingleCalculator, isSinglePackage } from '../../reducers/packages';
import { shouldShowSelectPackage } from '../../utils/shouldShowSelectPackage';
import { BASIC_PACKAGE_ID } from '../../utils/packagesUtils/constants';
import { hasRecommendedPackage } from '../../utils/packagesUtils';
import { setCheckoutDrawerOpen } from '../../actions/checkoutPlan';
import { isGigWithActiveWorkProcess } from '../../reducers/workProcess';
import { shouldShowRequestToOrderBtn } from '../PackagesTabs/TabContent/TabFooter/RequestToOrderBtn/utils/shouldShowRequestToOrderBtn';
import SellerTranslationBadgeWrapper from '../shared/SellerTranslationBadgeWrapper';
import { validateOrderDetails } from '../../utils/mainComponentsValidator';
import { ORDER_DETAILS } from '../../utils/pageSections';
import { Waypoint } from '../Waypoint/Waypoint';
import { MEDIA_GRID_MD } from '../../utils/constants';
import PackageExtras from './PackageExtras';
import GigQuantity from './GigQuantity';
import './style.scss';

const OrderDetails = (
    { features, currentPrice, calculator, hasRecommendedPackage, setCheckoutDrawerOpen, isMilestoneGig },
    { general, rollouts, gig: { instantOrderSettings }, currencyConverter }
) => {
    const { shouldDisplayFees } = useGigPageContext();
    const formattedPrice = currencyConverter.convertWithFee(currentPrice);
    const showRequestToOrder = shouldShowRequestToOrderBtn({
        instantOrderSettings,
        packageId: BASIC_PACKAGE_ID,
        rollouts,
    });
    const showSelectPackage = !showRequestToOrder && shouldShowSelectPackage(general);
    const contentClassNames = classNames('order-details-content', { recommended: hasRecommendedPackage });
    const displayGigQuantity = !isMilestoneGig && !calculator;

    const onClick = () => {
        setCheckoutDrawerOpen(true);
    };

    return (
        <div className="order-details">
            <h2 className="section-title">
                <SellerTranslationBadgeWrapper><I18n k="gig_page_perseus.packages.order_details" /></SellerTranslationBadgeWrapper>
            </h2>
            <div className={contentClassNames}>
                <PackageContent
                    id={BASIC_PACKAGE_ID}
                    features={features}
                    showPrice={false}
                    forceNonCollapsable={true}
                />
                {displayGigQuantity && <GigQuantity />}
                <PackageExtras />
                {showSelectPackage && (
                    <Stack style={{paddingTop: "16px", paddingBottom: "16px"}} justifyContent="flexEnd" borderTopWidth="sm" borderColor="grey_400">
                        <Button onClick={onClick}>
                            <Text fontWeight="bold" color="white" as="span"><I18n k="gig_page_perseus.packages.continue" /></Text>
                            <Text fontWeight="bold" color="white" as="span" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap"> ({formattedPrice}
                                {shouldDisplayFees && (
                                    <Text fontWeight="bold" color="white" paddingLeft="1.5" as="span">
                                        <I18n k="gig_page_perseus.packages.plus_fees" />
                                    </Text>
                                )}
                                )</Text>
                        </Button>
                    </Stack>
                )}
            </div>
        </div>
    );
};

OrderDetails.propTypes = {
    features: array,
    currentPrice: number,
    calculator: object,
    hasRecommendedPackage: bool,
    isMilestoneGig: bool,
    setCheckoutDrawerOpen: func,
};

OrderDetails.contextTypes = {
    general: object,
    rollouts: object,
    gig: object,
    biEvents: object,
    currencyConverter: object,
};

const mapStateToProps = (state) => {
    const { packages } = state;

    const { currentPrice, features } = getPackageById(packages, BASIC_PACKAGE_ID);
    const calculator = getSingleCalculator(packages, BASIC_PACKAGE_ID);

    return {
        features,
        currentPrice,
        calculator,
        hasRecommendedPackage: hasRecommendedPackage(packages.packageList),
        isMilestoneGig: isGigWithActiveWorkProcess(state),
    };
};

const mapDispatchToProps = { setCheckoutDrawerOpen };

const OrderDetailsConnected = connect(mapStateToProps, mapDispatchToProps)(OrderDetails);

const OrderDetailsWrapper = ({ showOrderDetails }) => {
    const isClient = useIsClient();
    const { general } = useGigPageContext();
    const { isLimitedEdition } = general;

    if (!isClient || !showOrderDetails || isLimitedEdition) {
        return null;
    }

    return (
        <MediaQuery query={`(min-width: ${MEDIA_GRID_MD})`}>
            <Waypoint source={ORDER_DETAILS} />
            <OrderDetailsConnected />
        </MediaQuery>
    );
};

OrderDetailsWrapper.propTypes = {
    showOrderDetails: bool,
};

const mapStateToPropsWrapper = ({ packages }) => ({
    showOrderDetails: validateOrderDetails({ isSinglePackage: isSinglePackage(packages) }),
});

const OrderDetailsWrapperConnected = connect(mapStateToPropsWrapper)(OrderDetailsWrapper);

export { OrderDetailsWrapper, OrderDetails };
export default SafePureComponent(OrderDetailsWrapperConnected);
